.leaflet-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.leaflet-grab {
	cursor: auto;
}

.leaflet-dragging .leaflet-grab{
	cursor: move;
}